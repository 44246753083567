$pillRoundness: 30px;

.pillGroup {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0.5rem 1rem;
}

.pillButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #333;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 700;

  &:not(:first-child) {
    border-left:none;
  }

  &:first-child {
    border-radius: $pillRoundness 0 0 $pillRoundness;
  }

  &:last-child {
    border-radius: 0 $pillRoundness $pillRoundness 0;
  }
}