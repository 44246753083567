@import 'styles/variables';
$pinsize: 42px;
$pinmargin: 5px;
$pinColor: #dddddd;
$pinColorDark: #015C92;
$switchColor: $lightGrey;
$switchColorDark: #002D48;
$iconColor: #000000;
$iconColorDark: #FFFFFF;

$pinsizeMobile: 35px;
$pinmarginMobile: 3px;

$textMargin: 8px;

.darkmodeSwitch {
  transition: all 0.4s ease-out;
  position: relative;
  width: 80px;
  height: calc($pinsize - 10px);
  border: none;
  border-radius: 30px;
  margin: 5px;
  background-color: $switchColor;
  padding: 0 12px;
  &:hover {
    .pin {
      background-color: darken($pinColor, 10);
    }
  }

  .text {
    color: $iconColor;
    font-size: 10px;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.4s;
    z-index: 1;
    &Light {
      left: $textMargin;
    }
    &Dark {
      right: $textMargin;
    }
  }

  .pin {
    position: absolute;
    width: $pinsize;
    height: $pinsize;
    aspect-ratio: 1;
    border-radius: 50%;
    border: none;
    top: 50%;
    background-color: $pinColor;
    transform: translateY(-50%);
    transition: all 0.4s ease-out;
    z-index: 2;
    left: calc(100% - $pinsize);
    & ~ .textDark {
      opacity: 0;
    }
  }
}
.mobile {
  &.darkmodeSwitch {
    border: 1px solid rgba($switchColorDark, 0.5);
    height: 42px;
    background-color: white;
  }
  & .pin {
    left: calc(100% - $pinsize + 4px);
    width: $pinsize - 8px;
    height: $pinsize - 8px;
  }
}

:global(.darkmode) {
  .darkmodeSwitch {
    background-color: $switchColorDark;
    .text {
      color: $iconColorDark;
    }
    .pin {
      left: 4px;
      background-color: $pinColorDark;
      & ~ .textLight {
        opacity: 0;
      }
      & ~ .textDark {
        opacity: 1;
      }
    }

    &.mobile {
      .mobilePin {
        &::before {
          background-color: white;
        }
      }
    }
  }
}
