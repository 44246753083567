@import 'styles/variables';

.svg svg {
  circle {
    fill: $premiumDark;
  }
  path {
    fill: white;
  }
}
